<template>
    <div class="page_safe container">
      <div class="header_gruop page_width flex_sb">
        <div class="icon_home flex_center" @click="toBack()"><svg t="1729563443244" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6080" id="mx_n_1729563443245" width="16" height="16"><path d="M398.912 513.152l377.28-384.256c27.904-28.416 27.904-74.432 0-102.784-27.84-28.352-73.024-28.352-100.8 0L247.808 461.504C233.792 475.776 226.88 494.464 226.944 513.152 226.88 531.84 233.792 550.464 247.808 564.736l427.584 435.392c27.84 28.352 73.024 28.352 100.8 0 27.904-28.352 27.904-74.368 0-102.656L398.912 513.152z" fill="#16181c" p-id="6081"></path></svg></div>
        <div class="header_title">Help</div>
        <div></div>
      </div>
  
      <div class="scoller grid_content" :v-infinite-scroll="false" style="overflow:auto;-webkit-overflow-scrolling: touch">
          <div class="safe_content">
            <div class="safe_title">Customer service</div>
            <div class="coupon-cell flex_sb " @click="toPage('/aboutus')">
                <div class="cell_title strong">About us</div>   
                <div class="cell_more flex">
                    <div class="cell_icon flex_center">
                        <svg t="1729158554238" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3634" width="16" height="16"><path d="M383.291616 808.954249c-5.175883 0-10.353812-1.950422-14.338566-5.862521-8.064676-7.919367-8.182356-20.877493-0.26299-28.942169l273.602402-278.620695L368.69006 216.907145c-7.919367-8.064676-7.801686-21.022803 0.26299-28.942169 8.065699-7.918343 21.022803-7.80271 28.942169 0.26299l287.685141 292.960285c7.818059 7.961322 7.818059 20.717857 0 28.67918L397.895219 802.826692C393.887952 806.907637 388.591319 808.954249 383.291616 808.954249z" p-id="3635" fill="#16181c"></path></svg>
                    </div>
                </div>    
            </div>
            <div class="coupon-cell flex_sb " @click="toPage('/delivery')">
                <div class="cell_title strong">Promise of fast delivery</div>   
                <div class="cell_more flex">
                    <div class="cell_icon flex_center">
                        <svg t="1729158554238" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3634" width="16" height="16"><path d="M383.291616 808.954249c-5.175883 0-10.353812-1.950422-14.338566-5.862521-8.064676-7.919367-8.182356-20.877493-0.26299-28.942169l273.602402-278.620695L368.69006 216.907145c-7.919367-8.064676-7.801686-21.022803 0.26299-28.942169 8.065699-7.918343 21.022803-7.80271 28.942169 0.26299l287.685141 292.960285c7.818059 7.961322 7.818059 20.717857 0 28.67918L397.895219 802.826692C393.887952 806.907637 388.591319 808.954249 383.291616 808.954249z" p-id="3635" fill="#16181c"></path></svg>
                    </div>
                </div>    
            </div>
            <div class="coupon-cell flex_sb " @click="toPage('/reliable')">
                <div class="cell_title strong">Trusted and Reliable Products</div>   
                <div class="cell_more flex">
                    <div class="cell_icon flex_center">
                        <svg t="1729158554238" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3634" width="16" height="16"><path d="M383.291616 808.954249c-5.175883 0-10.353812-1.950422-14.338566-5.862521-8.064676-7.919367-8.182356-20.877493-0.26299-28.942169l273.602402-278.620695L368.69006 216.907145c-7.919367-8.064676-7.801686-21.022803 0.26299-28.942169 8.065699-7.918343 21.022803-7.80271 28.942169 0.26299l287.685141 292.960285c7.818059 7.961322 7.818059 20.717857 0 28.67918L397.895219 802.826692C393.887952 806.907637 388.591319 808.954249 383.291616 808.954249z" p-id="3635" fill="#16181c"></path></svg>
                    </div>
                </div>    
            </div>
            <div class="coupon-cell flex_sb " @click="toPage('/refund')">
                <div class="cell_title strong">Refund Policy </div>   
                <div class="cell_more flex">
                    <div class="cell_icon flex_center">
                        <svg t="1729158554238" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3634" width="16" height="16"><path d="M383.291616 808.954249c-5.175883 0-10.353812-1.950422-14.338566-5.862521-8.064676-7.919367-8.182356-20.877493-0.26299-28.942169l273.602402-278.620695L368.69006 216.907145c-7.919367-8.064676-7.801686-21.022803 0.26299-28.942169 8.065699-7.918343 21.022803-7.80271 28.942169 0.26299l287.685141 292.960285c7.818059 7.961322 7.818059 20.717857 0 28.67918L397.895219 802.826692C393.887952 806.907637 388.591319 808.954249 383.291616 808.954249z" p-id="3635" fill="#16181c"></path></svg>
                    </div>
                </div>    
            </div>
           
          </div>
         
      </div>
    </div>
  </template>
  
  <script>
  export default {
      methods:{
          toBack(){
              this.$router.back()
          },
          toPage(path){
            this.$router.push(path)
          }
      }
  }
  </script>
  
  <style lang="scss" scoped>
  
  .log_out{
    position: absolute;
    height: 50px;
    background:#fff;
    color:#f77e1a;
    left:0;
    right:0;
    top:70%;
    cursor: pointer;
  }
  </style>